<script setup lang="ts">
import { useThrottleFn } from '@vueuse/core'

defineProps({
  headline: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
  eyebrow: {
    type: String,
    required: false,
  },
  showBreadcrumb: {
    type: Boolean,
    default: true,
    required: false,
  },
})

// use nuxt head to add link preload for video
useHead({
  link: [
    {
      rel: 'preload',
      as: 'video',
      href: 'https://butlersherborn.b-cdn.net/cotswolds-loop.webm',
    },
  ],
})

// Refs for DOM elements
const heroImageRef = ref<HTMLElement | null>(null)
const videoRef = ref<HTMLVideoElement | null>(null)
const overlayRef = ref<HTMLElement | null>(null)
const overlayOpacity = ref(0)
const showScrollArrow = ref(true)

const updateOverlayOpacity = () => {
  const scrollPosition = window.scrollY
  const largeImage = heroImageRef.value
  const overlay = overlayRef.value

  if (!largeImage || !overlay) {
    console.error('Required elements not found')
    return
  }

  const bottomBuffer = 500
  const imageBottomPosition = (largeImage.getBoundingClientRect().bottom + window.scrollY) - bottomBuffer
  const scrollPercent = scrollPosition / imageBottomPosition

  if (scrollPercent >= 1) {
    overlayOpacity.value = 0.9
    showScrollArrow.value = false
  }
  else if (scrollPercent > 0 && scrollPercent < 1) {
    overlayOpacity.value = scrollPercent * 0.9
    showScrollArrow.value = false
  }
  else {
    overlayOpacity.value = 0
    showScrollArrow.value = true
  }
}

const throttledUpdateOverlayOpacity = useThrottleFn(updateOverlayOpacity, 20)

onMounted(() => {
  updateOverlayOpacity()
  window.addEventListener('scroll', throttledUpdateOverlayOpacity)

  const options: IntersectionObserverInit = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        videoRef.value?.play()
      }
      else {
        videoRef.value?.pause()
      }
    })
  }, options)

  if (heroImageRef.value) {
    observer.observe(heroImageRef.value)
  }
})

onUnmounted(() => {
  window.removeEventListener('scroll', throttledUpdateOverlayOpacity)
})

const scrollToSearch = () => {
  const content = document.getElementById('search')
  if (content) {
    content.scrollIntoView({ behavior: 'smooth' })
  }
}
</script>

<template>
  <div ref="heroImageRef" class="hero-image relative h-calc isolate w-full bg-gray-700">
    <video
      ref="videoRef"
      preload="metadata"
      muted
      loop
      playsinline
      loading="lazy"
      class="object-cover object-center w-full h-full"
      poster="/video/poster.webp"
      playbackRate="0.8"
    >
      <source src="https://butlersherborn.b-cdn.net/cotswolds-loop.webm" type="video/webm">
      <track src="/video/cotswolds.vtt" kind="captions" srclang="en" label="english_captions">
    </video>

    <div class="absolute bg-gradient-to-b opacity-20 from-black via-transparent to-transparent mix-blend-multiply inset-0" />
    <div class="absolute bg-gradient-to-tr from-black/70 via-black/10 to-transparent mix-blend-multiply inset-0" />
    <div ref="overlayRef" :style="{ opacity: overlayOpacity }" class="absolute bg-forest-800 inset-0" />
    <div class="absolute inset-0 container-8xl h-calc flex items-end pb-8 lg:pb-20">
      <UiFader class="max-w-4xl">
        <div v-if="eyebrow" class="eyebrow light">
          {{ eyebrow }}
        </div>
        <h1 class="headline">
          {{ headline }}
        </h1>
        <h2 class="subtitle">
          {{ subtitle }}
        </h2>
        <div id="search">
          <UiFader>
            <slot />
          </UiFader>
        </div>
      </UiFader>
      <UiFader class="hidden lg:block absolute bottom-4">
        <button @click="scrollToSearch">
          <span class="sr-only">Scroll to find a property</span>
          <Icon
            name="ic:sharp-arrow-downward"
            class="text-white text-4xl transition-opacity duration-500 ease-in-out"
            :class="showScrollArrow ? 'opacity-70' : 'opacity-0'"
          />
        </button>
      </UiFader>
    </div>
  </div>
</template>

<style scoped>
.h-calc {
  height: calc(100svh);
}

@media (max-width: 768px) {
  .h-calc {
    height: calc(100lvh - 100px);
  }
}
</style>
